.ReactFlagsSelect-module_selectBtn__19wW7:after,
.ReactFlagsSelect-module_selectBtn__19wW7[aria-expanded="true"]:after {
  display: none !important;
}

.ReactFlagsSelect-module_selectBtn__19wW7 {
  padding: 0 !important;
  border: none !important;
}

.ReactFlagsSelect-module_fullWidthOptions__1XeR6 {
  width: 200px !important;
}
