.error input {
  border: 1px solid #da4a34;
}

.css-1u9des2-indicatorSeparator {
  display: none;
}

p {
  margin-top: 0 !important;
}