/* @import '~antd/dist/antd.css'; */
/* @import url("base.css");
@import url("multiSelect.css");
@import url("country-select.css");
@import "rsuite/dist/rsuite.css"; */

/* @import "react-responsive-carousel/lib/styles/carousel.min.css"; */

/* Mona Sans - Variable Font */

@font-face {
  font-family: 'Mona Sans';
  src:
    url('Mona-Sans.woff2') format('woff2 supports variations'),
    url('Mona-Sans.woff2') format('woff2-variations');
  font-weight: 200 900;
  font-stretch: 75% 125%;
}

@font-face {
  font-family: 'Mono Sans Bold Wide';
  src:
    url('Mona-Sans-BoldWide.woff2') format('woff2 supports variations'),
    url('Mona-Sans-BoldWide.woff2') format('woff2-variations');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src:
    url('Inter-Medium.woff2') format('woff2 supports variations'),
    url('Inter-Medium.woff2') format('woff2-variations');
  font-weight: 200 900;
  font-stretch: 75% 125%;
}

@font-face {
  font-family: 'Inter';
  src:
    url('Inter-Regular.woff2') format('woff2 supports variations'),
    url('Inter-Regular.woff2') format('woff2-variations');
  font-weight: 200 900;
  font-stretch: 75% 125%;
}

* {
  font-family: 'Inter';
}

p, span, label {
  font-family: 'Inter' !important;
}

.mono-sans {
  font-family: 'Mona Sans' !important;
}

.mono-sans-bold-wide {
  font-family: 'Mono Sans Bold Wide', monospace;
  font-weight: bold;
  letter-spacing: 0.1em; /* Adjust this for the desired 'wide' effect */
}

.custom-otp-input input {
  font-size: 1.5rem;
  font-weight: 500;
  color: #150326;
  border-radius: 0.75rem;
  border: 1px solid #e4e4e9;
  width: 3.5rem !important;
  height: 3.5rem;
  text-align: center;
  margin: 0.5rem;
  padding: 0;
  outline: none;
  box-shadow: none;
  transition: all 0.3s ease-in-out;
}

/* media queries */
@media (max-width: 576px) {
  .custom-otp-input input {
    font-size: 1.5rem;
    width: 2.5rem !important;
    height: 2.5rem !important;
  }
}

@media screen and (min-width: 2000px) {
  .custom-otp-input input {
    font-size: 3rem;
    width: 6rem !important;
    height: 6rem !important;
  }
}

/* CSS Spinner Animation */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Styles for the spinner container */
.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  width: 20px
}

/* Styles for the spinner itself */
.spinner {
  border: 2px solid rgba(0, 0, 0, 0.3);
  border-top: 2px solid #c9c9c9; /* Change color as needed */
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
}


.szh-menu--state-open {
  padding: 10px;
  border-radius: 10px;
  background: #fff;
  margin-right: 10px;
  border: 1px solid #eee;
  box-shadow: #e4e4e9;
}

.rs-picker-toolbar-right .rs-btn-primary {
  background-color: #150326 !important;
  color: #fff !important;
}

.rs-picker-popup {
  z-index: 99 !important
}

.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
  background-color: #150326 !important;
}

.rs-stack-item button {
  color: #667368 !important;
  padding: 10px, 16px, 10px, 16px !important;
  border-radius: 8px !important;
  border: 1px solid #E2E8E9 !important;
}

.rs-picker-date-predefined .rs-stack-item {
  margin: 7px 5px !important;
}

.rs-input {
  cursor: pointer !important;
}

.custom-back-drop {
  -webkit-backdrop-filter: blur(15px) !important;
  backdrop-filter: blur(15px) !important;
}

.rs-carousel {
  height: auto !important;
}

.rs-carousel-label-wrapper input:checked~label {
  background-color: #7EA05C !important;
}

.rs-carousel-slider-after, .rs-carousel-slider-item {
  background: #fff !important;
}

.rs-carousel {
  background: #fff !important;
}